.transition-enter {
  opacity: 0;
}

.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.transition-enter-done {
  opacity: 1;
}

.transition-exit {
  opacity: 1;
}

.transition-exit.transition-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.transition-exit-done {
  opacity: 0;
}
