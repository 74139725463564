@font-face {
  font-family: Pangram;
  src: url("../../fonts/Pangram-Regular.otf");
}

@font-face {
  font-family: PangramBold;
  src: url("../../fonts/Pangram-Bold.otf");
}

@font-face {
  font-family: PangramBlack;
  src: url("../../fonts/Pangram-Black.otf");
}

* {
  font-family: "Pangram", Arial, Helvetica, sans-serif;
}

.font-bold {
  font-family: "PangramBold", Arial, Helvetica, sans-serif;
}

.font-black {
  font-family: "PangramBlack", Arial, Helvetica, sans-serif;
}
